.quill-delta {
	color: #4C4C4C;
	font-size: 16px;

	.delta-single {
		margin-bottom: 4px;
	}

	ul {
		display: block;
		list-style-type: disc;
		margin-block-start: 0px;
		margin-block-end: 0px;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
		padding-inline-start: 3px;
		padding-left: 22px;
		position: relative;


		&::before {
			position: absolute;
			top: 8px;
			left: 8px;
			content: "";
			min-width: 4px;
			min-height: 4px;
			background-color: #222222;
			border-radius: 50%;
		}
	}

	blockquote {
		display: block;
		margin-block-start: 6px;
		margin-block-end: 6px;
		margin-inline-start: 0;
		margin-inline-end: 6px;
		padding-left: 6px;
		border-left: 3px solid #c5c5c5;
	}

	h1,
	h2,
	h3 {
		margin: 0;
		margin-top: 10px;
		margin-bottom: 6px;
		font-weight: normal;
	}

	h1 {
		font-size: 26px;
	}

	h2 {
		font-size: 22px;
	}

	h3 {
		font-size: 16px;
	}
}