@font-face {
	font-family: SFProDisplay;
	src: url('../../fonts/FontsFree-Net-SFProDisplay-Regular.ttf');
}

img {
	max-width: 100%;
}

body {
	margin: auto;
	background-color: #c5c5c5;
	font-family: SFProDisplay;
	color: #222222;
}

video {
	width: 100%;
	max-width: 100%;
}

* {}

.text-bold {
	font-weight: bold;
}

.text-italic {
	font-style: italic;
}

.text-underline {
	text-decoration: underline;
}

.text-left {
	text-align: left;
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

.view-card {
	margin-bottom: 22px;

	.card-header {
		background-color: #3072dd;
		text-align: center;
		padding: 3px;
		position: fixed;
		left: 0;
		right: 0;
		z-index: 99;

		.header-logo {
			display: inline-block;
			background-color: white;
			padding: 0px 18px;
			border: 1px solid #707070;
		}
	}

	.header-shape {
		position: fixed;
		background-color: #3072dd;
		width: 100%;
		height: 0;
		border-top: 100px solid #3072dd;
		border-right: 100px solid transparent;
	}

	.after-header {
		height: 80px;
	}

	.card-content {
		max-width: 350px;
		margin: auto;
		overflow: hidden;
		border-radius: 23px;
		box-shadow: 0px 5px 6px 3px #0000008a;
		background-color: white;
		padding-bottom: 16px;

		.card-loading {
			text-align: center;
			margin-top: 18px;
			padding-top: 20px;
		}

		.header-area {
			position: relative;

			.profile-cover {
				// margin-bottom: 75px;

				img {
					width: 100%;
					border-radius: 23px 23px 0 0;
				}
			}

			.profile-photo {
				position: absolute;
				left: calc(50% - 75px);
				bottom: -75px;
				border-radius: 50%;
				overflow: hidden;
				width: 150px;
				height: 150px;
				border: 3px solid white;

				img {
					width: 100%;
				}
			}
		}

		&.not-cover-photo {
			&.has-profile-photo {
				.header-area {
					margin-top: 50px;

					.profile-photo {
						bottom: -135px;
					}
				}

				.basic-area {
					padding-top: 136px;
				}
			}

			&.not-profile-photo {
				.basic-area {
					padding-top: 30px;
				}
			}
		}

		&.has-cover-photo {
			&.has-profile-photo {
				.basic-area {
					padding-top: 80px;
				}
			}
		}

		.basic-area {
			text-align: center;
			padding-top: 6px;
			padding-bottom: 10px;

			.info-name {
				font-size: 25px;
				color: black;
			}

			.info-position {
				margin-top: 9px;
				font-size: 16px;
			}
		}

		.action-area {
			padding: 10px 0;
			display: flex;
			justify-content: space-evenly;

			.action-single {
				text-align: center;
				cursor: pointer;

				.action-icon {
					font-size: 22px;
				}

				.action-name {}
			}
		}

		.tabs-area {
			.single-tab {
				margin-bottom: 1px;

				.collapsible {
					.collapsible-header {
						display: block;
						background-color: red;
						cursor: pointer;
						padding: 16px 12px;
						font-size: 17px;
						font-weight: bold;
						color: white;
						background-color: var(--primaryColorDark);
						text-align: center;
						position: relative;

						&:after {
							font-family: 'Font Awesome 5 Free';
							content: '\f107';
							position: absolute;
							right: 18px;
							top: 18px;
							color: black;
							display: block;
							transition: transform 300ms;
						}
					}

					.collapsible-content {
						padding: 0;
						max-height: 0;
						transition: max-height 500ms ease-out;
						overflow: hidden;

						a {
							text-decoration: none;
						}

						.content-inner {
							padding: 8px;
						}
					}

					&.is-open {
						.collapsible-header {
							&:after {
								transform: rotateZ(180deg);
							}
						}

						.collapsible-content {
							max-height: 5000px;
							transition: max-height 500ms ease-in;

						}
					}
				}

				.Collapsible .Collapsible__trigger {
					display: block;
					background-color: red;
					cursor: pointer;
					padding: 18px 12px;
					font-size: 17px;
					font-weight: bold;
					color: white;
					background-color: #3072dd;
					text-align: center;
				}

				.Collapsible__contentInner {
					padding: 8px;
				}

				.list-videos {
					.preview-photo {
						position: relative;
						cursor: pointer;

						.play-button {
							position: absolute;
							background: rgba(0, 0, 0, 0.2);
							left: 0;
							right: 0;
							top: 0;
							bottom: 4px;
							display: flex;
							justify-content: center;
							align-items: center;

							&>i {
								color: rgba(218, 218, 218, 0.6);
								font-size: 4.23em;
								transition: color 0.3s ease;
							}
						}

						&:hover {
							i {
								color: rgba(0, 0, 0, 0.5);
							}
						}
					}

					.playlist {
						padding-top: 6px;
						display: grid;
						grid-column-gap: 3px;
						grid-template-columns: auto auto auto auto;

						.item-single {
							cursor: pointer;
							position: relative;

							img {
								max-height: 45px;
								width: 100%;
								object-fit: cover;
							}

							.play-button {
								position: absolute;
								background: rgba(0, 0, 0, 0.2);
								left: 0;
								right: 0;
								top: 0;
								bottom: 4px;
								display: flex;
								justify-content: center;
								align-items: center;

								&>i {
									color: rgba(218, 218, 218, 0.6);
									transition: color 0.3s ease;
								}
							}

							&:hover {
								i {
									color: rgba(0, 0, 0, 0.5);
								}
							}
						}
					}
				}

				.recruiting-profile {}

				&.type-SMM {
					background-color: #1c68b8;
					padding: 1rem;
					text-align: center;

					.all-links {
						display: inline-block;

						a.smm-icon {
							color: white;
							padding: .618rem;
						}
					}
				}
			}
		}
	}
}

.guest-form {
	position: fixed;
	background: #00000094;
	z-index: 101;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	.area-form {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		background: white;
		border-radius: 18px 18px 0 0;
		padding: 12px;

		.custom-title {
			font-size: 22px;
			font-weight: bold;
			border-bottom: 1px solid #b5b5b5;
			margin-bottom: 18px;
			padding-bottom: 6px;
			text-align: center;
		}

		.form-header {
			display: flex;
			justify-content: space-between;
			border-bottom: 2px solid #d7d7d9;
			padding-bottom: 9px;


			.header-title {
				font-size: 20px;
				font-weight: bold;
			}

			.header-submit {
				button {
					clear: both;
					border: none;
					background: none;
					color: #4cae50;
					font-weight: bold;
					font-size: 20px;
					cursor: pointer;

					&:focus {
						outline: none;
					}
				}
			}

		}

		.form-fields {
			padding: 18px 0;

			.single-filed {
				margin-bottom: 8px;

				.filed-label {
					display: block;
					color: #a4a4a5;
					font-size: 14px;
					font-weight: normal;
				}

				.filed-input {
					width: calc(100% - 4px);
					padding: 3px;
					outline: none;
					border: none;
					border-bottom: 2px solid #e6e6e8;
				}
			}
		}
	}
}